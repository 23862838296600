.formAppRsvp {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
  }
  
  .formAppRsvp form{
      padding: 0px 60px;
      border-radius: 10px;
  }
  
  .formAppRsvp h1{
      color: rgba(250, 250, 250, 0.997);
      text-align: center;
  }
  
 .formAppRsvp button{
      width: 51%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: rgb(153, 119, 51);
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }

.formInputRsvp{
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.formInputRsvp input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 0px;
    border: 1px solid gray;
}


.formInputRsvp label{
    font-size: 12px;
    color: black;
}

.formInputRsvp.invalid span {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

.formInputRsvp.invalid input:invalid + span {
  display: block;
}

.formInputRsvp.invalid input:invalid {
  border: 1px solid red;
}

.formInputRsvp.invalid input.invalid {
  border: 1px solid red;
}

.formInputRsvp.invalid input.invalid + span {
  display: block;
  border: 1px solid red;
}

@media (max-width: 300px) {
  .formAppRsvp form {
    width: 120%;
  }
  .formAppRsvp label {
    width: 70%;
  }
  .formAppRsvp input {
    width: 70%;
  }
  .formAppRsvp span {
    width: 70%;
  }
}