.formInput {
  display: flex;
  flex-direction: column;
  width: 280px;
  accent-color: black;
}

input:focus{
  outline: 2px solid rgb(153, 119, 51);
  }

select:focus{
  outline: 2px solid rgb(153, 119, 51);
  }

.formInput input {
  padding: 15px;
  margin: 10px 0px;
  border: 1px solid black;
  border-radius: 0px;
}

.formInput select {
  border: 1px solid black !important;
  border-radius: 0px !important;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  /* and then whatever styles you want*/
  padding: 5px;
  color: black;
  background-color: white;
}

.formInput label {
  font-size: 12px;
  color: black;
}

.formInput span {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: center;
}

.formInput input:invalid[focused="true"]{
  border: 1px solid red;
}

.formInput input:invalid[focused="true"] ~ span{
    display: block;
}