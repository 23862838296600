.formRsvp {
    overflow-x: hidden;
}

.formText {
    margin-right: 39px;
}

.directFormText {
    padding-top: 20px;
    margin-top: 23px;
    margin-right: 0px;
    justify-content: center;
}

.formApp {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    margin-right: 35px;
  }

.directFormApp {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    margin-left: 5px;
  }
  
  .formApp form{
      border-radius: -1000px;
      max-width: -600px;
  }
  
  .formApp h1{
      color: rgba(250, 250, 250, 0.997);
      text-align: center;
  }
  
 .formApp button{
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      background-color: rgb(153, 119, 51);
      color: white;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
  }

  .directFormApp form{
    border-radius: -1000px;
    max-width: -600px;
}

.directFormApp h1{
    color: rgba(250, 250, 250, 0.997);
    text-align: center;
}

.directFormApp button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: rgb(153, 119, 51);
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
}

  @media (max-width: 320px) {
    .formApp {
        transform: scale(0.72);
        position: relative;
        top: -75px;
    }
    .directFormApp {
        transform: scale(0.85);
        position: relative;
        top: -33px;
    }
  }

  @media (min-width: 321px) and (max-width: 380px) {
    .formApp {
      transform: scale(0.89);
      position: relative;
      top: -30px;
    }
  
    .form-input {
      width: 100%; /* Adjust the width as needed */
      font-size: 12px; /* Adjust the font size as needed */
      padding: 4px; /* Adjust the padding as needed */
    }
  
    .form-label {
      font-size: 12px; /* Adjust the font size as needed */
      margin-bottom: 2px; /* Adjust the margin as needed */
    }
  
    .button {
      font-size: 12px; /* Adjust the font size as needed */
      padding: 4px 8px; /* Adjust the padding as needed */
    }
  }