.App {
  text-align: center;
  overflow-y: scroll;
  position: relative;
}

.App::before {
  content: "";
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-attachment: auto;
  background-size: 100% calc(100% + env(safe-area-inset-top) + env(safe-area-inset-bottom));
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
