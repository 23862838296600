
.main {
  display: flex;
  width: 100%;
  padding-top: 30px;
  justify-content: center;
  color: white !important;

}
.faq {
  max-width: 800px;
}